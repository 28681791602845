.my_NavBar{
    background-image: url('../assets/img/navbar.jpg');
    
}
.my_Footer{
    
    background-color:black;
    
}

.change_Color{
    color: antiquewhite;
    
}

.NavbarToggler{
    background-color:darkblue;
}

@media screen and (max-width:800px){
    .aboutPage{
        position:absolute;
        top: 310px;
        left: 155px;
        width: 55%;
        opacity: 0.8;
        animation: .4s rollout2;
    }
    }